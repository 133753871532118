import React from "react";
import * as styles from "./howWeDoItWide.module.scss"
import JSONData from "../../../../content/embeddedHowWeDoItData.json";
import {Col, Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import HowWeDoItCardWide from "./HowWeDoItCardWide";

export default function HowWeDoItWide() {
    const data = useStaticQuery(graphql`{
        image0: file(relativePath: {eq: "embedded/howWeDoIt/image-1.png"}) {
          childImageSharp {
            gatsbyImageData(quality: 70, layout: CONSTRAINED)
          }
        }
    }`);

    return <div>
        <div className={styles.header}>{JSONData.header}</div>
        <div className={styles.text}>{JSONData.text}</div>
        <Row className={styles.cardListWrapper}>
            {JSONData.cards.map((data: any, idx: number) => {
                return <Col key={idx} className={styles.cardWrapper} md={{offset: idx % 2 === 0 ? 1 : 0, span: 5}}
                            xl={{offset: 0, span: 4}}><HowWeDoItCardWide header={data.title} text={data.text}
                                                                     number={idx + 1}/></Col>
            })}
            <Col className={styles.imageCardWrapper} md={{span: 5}}
                 xl={{offset: 0, span: 4}}>
                <GatsbyImage alt={"person"} image={data.image0.childImageSharp.gatsbyImageData}/>
            </Col>
        </Row>
    </div>
}
