import React from "react";
import {Col, Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import * as styles from "./servicesDetails.module.scss"


export default function ServicesDetails(props: ServicesDetailsProps) {

    const data = useStaticQuery(graphql`{
    image1: file(relativePath: {eq: "embedded/details/image-1.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image2: file(relativePath: {eq: "embedded/details/image-2.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image3: file(relativePath: {eq: "embedded/details/image-3.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image4: file(relativePath: {eq: "embedded/details/image-4.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        }
    }`);

    return <div className={styles.detailsWrapper}>
        <Row>
            <Col className={styles.imageCol} xs={{span: 12}} lg={{span: 6}}>
                <GatsbyImage className={styles.image} alt={"person"}
                             image={data.image1.childImageSharp.gatsbyImageData}/>
            </Col>
            <Col xs={{span: 12}} lg={{span: 6}} className={styles.firstRowWrapper}>
                <div>
                    <div className={`${styles.header} ${styles.top}`}>
                        {props.JSONData.header}
                    </div>
                    <div className={`${styles.text} ${styles.top}`}>
                        {props.JSONData.text}
                    </div>
                </div>
            </Col>
        </Row>
        {!props.hideSecondSection && (
            <>
                <div className={styles.images}>
                    <div className={`${styles.imageWrapper}  ${styles.imageHideMobile}`}>
                        <GatsbyImage alt={"person"}
                                    image={data.image2.childImageSharp.gatsbyImageData}/>
                    </div>
                    <div className={styles.imageWrapper}>
                        <GatsbyImage alt={"person"}
                                    image={data.image3.childImageSharp.gatsbyImageData}/>
                    </div>
                    <div className={`${styles.imageWrapper}  ${styles.imageHideMobile}`}>
                        <GatsbyImage alt={"person"}
                                    image={data.image4.childImageSharp.gatsbyImageData}/>
                    </div>
                </div>
                <div className={`${styles.header} ${styles.secondHeader}`}>
                    {props.JSONData.secondHeader}
                </div>
                <Row>
                    <Col xs={{span: 12}} lg={{span: 6}}>
                        <div className={`${styles.text} ${styles.bottom}`}>
                            {props.JSONData.secondText}
                        </div>
                    </Col>
                    <Col xs={{span: 12}} lg={{span: 6}} className={styles.lastColWrapper}>
                        <div className={`${styles.text} ${styles.thirdText}`}>
                            {props.JSONData.thirdText}
                        </div>
                    </Col>
                </Row>
            </>
        )}
    </div>
}

interface ServicesDetailsProps {
    JSONData: any;
    hideSecondSection?: boolean
}
