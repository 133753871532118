// extracted by mini-css-extract-plugin
export var bottom = "servicesDetails-module--bottom--c1c3e";
export var detailsWrapper = "servicesDetails-module--detailsWrapper--35e3b";
export var firstRowWrapper = "servicesDetails-module--firstRowWrapper--b6e73";
export var header = "servicesDetails-module--header--541f1";
export var image = "servicesDetails-module--image--ee7e6";
export var imageCol = "servicesDetails-module--imageCol--23e90";
export var imageHideMobile = "servicesDetails-module--imageHideMobile--0ba09";
export var images = "servicesDetails-module--images--a51fe";
export var lastColWrapper = "servicesDetails-module--lastColWrapper--f141c";
export var secondHeader = "servicesDetails-module--secondHeader--0e9b6";
export var text = "servicesDetails-module--text--ef7eb";
export var thirdText = "servicesDetails-module--thirdText--7d055";
export var top = "servicesDetails-module--top--c08af";