import React, {ReactElement} from "react"
import * as styles from "./howWeDoItCardWide.module.scss"

export default function HowWeDoItCardWide(props: ServiceCardComponentProps) {

    return <div className={styles.cardWrapper}>
        <div className={styles.headerWrapper}>
            <div className={styles.icon}>
                {props.number}
            </div>
            <div className={styles.header}>
                {props.header}
            </div>
        </div>
        <div className={styles.text}>
            {props.text}
        </div>
    </div>
}

interface ServiceCardComponentProps {
    header: string,
    text: string,
    number: number
}
